/* @import url("https://fonts.googleapis.com/css?family=Oswald&display=swap"); */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* This is the class for the business card  */
.businessCard {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: azure;
  transition: 0.3s;
  border-radius: 15px;
  width: 500px;
  height: 200px;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
}
/* Is the class for the image in the business card */
.bcImageClass {
  width: 100px;
  height: 100px;
}
/* This is the header and project title class */
.headerText {
  font-family: "Oswald", sans-serif;
  text-align: center;
  padding: 20px;
}
/* This is a clss for each project elements */
.projectElement {
  margin-left: auto;
  margin-right: auto;
  padding: 3px;
}

.contact-link {
  padding: 5px;
}
